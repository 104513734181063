<template>
  <!-- v-if="checkPermission(['designation','PUT'])" -->
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.sub_category_id"
        class="holamundo editModal"
        title="Update Sub Category"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Sub Category Name*</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Sub Category name"
                        v-model="form.sub_category_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Sub Category name')"
                        >{{ errors.first("Sub Category name") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ContractService from "@/services/ContractService.js";
import companyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        sub_category_name: "",
        sub_category_id: "",
        category_id: "",
        sub_category_isactive: "1",
      },

      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    console.log(
      "this.form, this.params.data :",
      this.form,
      this.params.data.sub_category_id
    );
    this.value = this.params.value;
    this.form = this.params.data;
    // this.getCompanyList();
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.sub_category_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let id = this.form.sub_category_id;
          let payload = {
            sub_category_name: this.form.sub_category_name,
            sub_category_id: this.form.sub_category_id,
            category_id: this.form.category_id,

            sub_category_isactive: this.params.data.sub_category_isactive,
          };

          ContractService.editContractSubCategory(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("conrefreshTableSubCategory", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {},
});
</script>
<style></style>
